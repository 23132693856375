.demo {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 500px; 
    height: 140px; 
    margin-top: -70px;
    padding: 10px; 
    border-radius: 20px;
    transform: translateX(-50%);
    background: #32386D; 
    font-family: Helvetica, Arial, sans-serif;
  }
  
  .demo__colored-blocks {
    overflow: hidden;
    position: absolute;
    left: 50%;
    top: 0;
    width: 500px; 
    height: 100%;
    margin-left: -250px; 
    padding: 10px; 
    border-radius: 20px; 
    perspective: 1000px;
    animation: demoAnim 4s ease-in-out infinite, contAnim 4s infinite;
  }
  
  .demo__colored-blocks-rotater {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    animation: rotation 1.3s linear infinite;
  }
  
  .demo__colored-blocks-inner {
    overflow: hidden;
    position: relative;
    height: 100%;
    background: #32386D;
    border-radius: inherit;
  }
  
  .demo__colored-block {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 300%;
    height: 300%;
    transform-origin: 0 0;
  }
  
  .demo__colored-block:nth-child(1) {
    transform: rotate(0deg) skewX(120deg);
    background-color: #FD3359; 
  }
  
  .demo__colored-block:nth-child(2) {
    transform: rotate(120deg) skewX(120deg);
    background-color: #F4D302; 
  }
  
  .demo__colored-block:nth-child(3) {
    transform: rotate(240deg) skewX(120deg);
    background-color: #21BDFF; 
  }
  
  .demo__inner {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
  }
  
  .demo__numbers {
    overflow: visible;
    position: absolute;
    left: 50%;
    top: 50%;
    width: 100px; 
    height: 100px;
    margin-left: -50px; 
    margin-top: -50px;
  }
  
  .demo__numbers-path {
    fill: none;
    stroke-width: 10px;
    stroke: #fff;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-dasharray: 0, 396.4067;
    stroke-dashoffset: 0;
    animation: numAnim 4s ease-in-out infinite;
    opacity: 0;
  }
  
  .demo__text {
    position: absolute;
    left: 50%;
    top: 0;
    width: 500px;
    height: 140px;
    margin-left: -250px; 
    text-align: center;
    line-height: 140px;
    font-size: 100px;
    color: #fff;
    text-transform: uppercase;
    letter-spacing: 15px;
    transform: translateX(10px);
  }
  
  @keyframes contAnim {
    15%, 100% {
      margin-left: -250px; 
      width: 500px; 
    }
    25%, 90% {
      margin-left: -70px;
      width: 140px;
    }
  }
  
  @keyframes numAnim {
    15% {
      stroke-dasharray: 0, 396.4067;
      stroke-dashoffset: 0;
      opacity: 0;
    }
    25%, 41% {
      opacity: 1;
      stroke-dasharray: 144.4257, 396.4067; 
      stroke-dashoffset: -40;
    }
    53%, 66% {
      stroke-dasharray: 136.0216, 396.4067; 
      stroke-dashoffset: -144.4257 - 42.8130 - 40;
    }
    76% {
      stroke-dasharray: 72.1555 + 41.3196, 396.4067;
      stroke-dashoffset: -144.4257 - 42.8130 - 136.0216 - 82.6393 -40;
    }
    88%, 100% {
      stroke-dasharray: 72.1555, 396.4067;
      stroke-dashoffset: -144.4257 - 42.8130 - 136.0216 - 82.6393 -40; 
    }
    92% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }
  
  @keyframes rotation {
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes demoAnim {
    15% {
      border-radius: 20px;
      transform: rotate(0);
    }
    30%, 43% {
      border-radius: 50%;
      transform: rotate(360deg);
    }
  
    52%, 65% {
      border-radius: 0;
      transform: rotate(720deg);
    }
  
    78%, 90% {
      border-radius: 50%;
      transform: rotate(1080deg);
    }
  
    100% {
      border-radius: 20px;
      transform: rotate(1440deg);
    }
  }
  